@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BrontoUltraBlack";
  src: url("./assets/form-fonts/Bronto-Ultrablack.ttf");
}

@font-face {
  font-family: "BourtonScriptBold";
  src: url("./assets/form-fonts/BourtonScriptBold.ttf");
}

@font-face {
  font-family: "CheltenHamCondensed";
  src: url("./assets/fonts/CheltenhamCondensedRegular.ttf");
}

body {
  margin: 0;
  font-family: "Roboto", BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static;
}
body.overflow-hidden {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h2-custom {
  font-size: 2rem;
  line-height: 1.2;
}

/* width */
.modalContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #6b7ca0;
  border-radius: 10px;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
  background: #2a313f;
  border-radius: 10px;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
  background: #53678f;
}
.main-content {
  width: 100%;
  height: 100%;
  background: url("./assets/img/640x640px_pozadina-min.jpeg");
  background-position: center center;
}
.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #091e47ad;
  display: none;
}
.not-active {
  cursor: auto;
}
.not-active .item-overlay {
  display: block;
  cursor: auto;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  transform: translateZ(0);
}

.modalContainer {
  width: 95%;
  max-width: 550px;
  min-width: 360px;
  height: 97%;
  min-height: 600px;
  border-radius: 12px;
  background: url("./assets/img/grafika/OBRAZAC-HR-veci.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  transform: none !important;
}
.modalContainer.modaThankYou {
  height: 860px;
}

.modalCloseButton {
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 50%;
  position: fixed;
  background: #14284c;
  cursor: pointer;
  z-index: 100;
}

.modalForm {
  border-image: linear-gradient(#edd88d, #c1a55a) 30;
  border-width: 5px;
  border-style: solid;
  padding: 9rem 1.3rem 1.8rem 1.3rem;
  position: relative;
  margin: 0 auto;
  color: #d5b971;
  font-size: 1.1rem;
  width: 80%;
  border-radius: 10px;
  margin-top: 18rem;
  margin-bottom: 2rem;
}
.modalForm.contentForm {
  padding: 5rem 1.8rem;
  margin-top: 0;
  width: 60%;
}
.contentFormBg {
  background: url("./assets/img/OBRAZAC-BIH-bg-fin.jpeg");
  background-position: top center;
  background-size: 105%;
}
.modalPrizeImage {
  position: absolute;
  width: 280px;
  z-index: 1;
  height: 280px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  top: -140px;
}
.modalPrizeTitles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: #091e47ed;
  border: 4px solid;
  flex-direction: column;
  visibility: hidden;
  text-align: left;
}
.modalPrizeTitles ol {
  list-style: none;
  padding-left: 22px;
  font-size: 14px;
  padding-right: 22px;
  font-weight: 500;
  text-align: center;
}
.modalPrizeTitles li {
  line-height: 1.2;
  margin-bottom: 10px;
}
.modalPrizeTitles li:last-child {
  margin-bottom: 0;
}
.modalPrizeTitles a.disabled-link {
  pointer-events: none;
}
.modalPrizeTitles strong {
  font-weight: 900;
}
.modalPrizeTitles a:hover {
  text-decoration: underline;
}
.modalPrizeImage:hover .modalPrizeTitles {
  visibility: visible;
}
.question-wrapper {
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
}
.answer-item {
  min-width: 45%;
  max-width: 45%;
}
.answer-item input {
  cursor: pointer;
}
.starTail {
  position: absolute;
  left: -14%;
  top: -240px;
}

.starHead {
  position: absolute;
  top: -298px;
  right: -40px;
  z-index: 2;
}

.modalFooterGraphic {
  width: 100%;
  height: 127px;
  background: url("./assets/img/grafika/DOLJE.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.modalSubmit {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: 16px;
  color: #1d274a;
  padding: 0.2rem 2rem;
  background-image: linear-gradient(to right, #edd88d 10%, #c1a55a) !important;
  font-family: "BrontoUltraBlack";
}

.basic-button {
  color: #1d274a;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 15px;
  background-image: linear-gradient(to right, #edd88d 10%, #c1a55a) !important;
  font-family: "BrontoUltraBlack";
}

.modalSubmit.loading {
  pointer-events: none;
  opacity: 0.7;
}

.fileUpload {
  text-transform: uppercase;
  font-size: 16px;
  color: #1d274a;
  padding: 0.2rem 2rem;
  background-image: linear-gradient(to right, #edd88d 10%, #c1a55a) !important;
  font-family: "BrontoUltraBlack";
}

.formLabel {
  /*font-family: "BourtonScriptBold";
  font-size: 25px;*/
  font-weight: bolder;
  line-height: 1;
  font-family: "Roboto Condensed";
  font-size: 20px;
  margin-bottom: 5px;
  display: block;
}
.formLabel-special {
  font-family: "Roboto Condensed";
  font-size: 20px;
}

.modalForm input,
.modalForm select {
  height: 32px;
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
}
.modalForm input[type="checkbox"] {
  height: 25px;
  width: 25px !important;
  min-width: 25px;
}

.modalForm select {
  width: 100%;
  height: 32px;
  color: #000000;
}

.modalForm input:focus {
  outline-style: solid;
  outline-color: #d5b971;
}

.modalForm textarea:focus {
  outline-style: solid;
  outline-color: #d5b971;
}

.modalForm textarea {
  height: 60px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

.modalForm input:not([type="checkbox"]),
select,
textarea {
  width: 100%;
  border-radius: 5px;
}

.mainContent {
  padding: 3rem 1rem 1rem 1rem;
}

.mainContent h1 {
  font-family: "CheltenHamCondensed";
}

.mainContent h2,
p {
  font-family: "Roboto Condensed";
}

.mainContent p {
  font-size: 1.3rem;
  text-align: center;
}

.mainContent p {
  margin-bottom: 0.5rem;
}

.mainContentStar {
  width: 100%;
  min-width: 300px;
  max-width: 1280px;
  height: intrinsic;
}

.mainContentPrizes {
  width: 100%;
  min-width: 300px;
  max-width: 870px;
}

.formError {
  width: 100%;
  background: #b32d3fd2;
  margin-top: 0.5rem;
  font-family: "Roboto Condensed";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d2ab9f;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px;
  line-height: 1.2;
}
.fileUpload {
  position: relative;
}
.fileUpload input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 680px) {
  .mainContent {
    padding: 0rem 0rem 0rem 0rem;
  }

  .mainContent p {
    font-size: 1.1rem;
  }

  .mainContent p {
    margin-bottom: 0.8rem;
  }
  .h2-custom {
    font-size: 1.5rem;
    line-height: 1.1;
  }
  .answer-item {
    min-width: 100%;
    max-width: 100%;
  }
  .modalContainer.modaThankYou {
    height: 95vh;
  }
  .modalContainer.modaThankYou h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 850px) {
  .modalForm.contentForm {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .content-hero {
    min-height: 500px !important;
  }

  .modalForm {
    margin-top: 17rem;
  }
}

@media only screen and (max-width: 400px) {
  .content-hero {
    min-height: 400px !important;
  }
}

@media only screen and (max-width: 510px) {
  .modalForm {
    width: 90%;
    padding: 8rem 1rem 1.4rem 1rem;
  }

  .modalPrizeImage {
    width: 230px;
    height: 230px;
    top: -115px;
  }
  .modalForm.contentForm {
    width: 97%;
  }
}

@media only screen and (max-width: 460px) {
  .starTail {
    position: absolute;
    left: -12%;
    top: -205px;
    width: 90%;
  }

  .starHead {
    position: absolute;
    top: -260px;
    right: -15px;
    z-index: 2;
    width: 64%;
  }

  .modalContainer {
    min-width: 310px;
  }
}

@media only screen and (max-width: 350px) {
  .starHead {
    top: -235px;
  }

  .starTail {
    position: absolute;
    left: -12%;
    top: -185px;
  }
}
